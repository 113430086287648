$font--light: 100;
$font--regular: 300;
$font--medium: 500;
$font--bold: 700;

// --------------------------------COMFORTAA--------------------------------
@font-face{
    font-family: "Montserrat";
    src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: $font--light;
}
@font-face{
    font-family: "Montserrat";
    src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: $font--regular;
}
@font-face{
    font-family: "Montserrat";
    src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: $font--medium;
}
@font-face{
    font-family: "Montserrat";
    src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: $font--bold;
}

// --------------------------------IBMPLEX--------------------------------
@font-face{
    font-family: "IBMPlex";
    src: url('../fonts/IBMPlexSansArabic-ExtraLight.ttf') format('truetype');
    font-weight: $font--light;
}
@font-face{
    font-family: "IBMPlex";
    src: url('../fonts/IBMPlexSansArabic-Regular.ttf') format('truetype');
    font-weight: $font--regular;
}
@font-face{
    font-family: "IBMPlex";
    src: url('../fonts/IBMPlexSansArabic-Medium.ttf') format('truetype');
    font-weight: $font--medium;
}
@font-face{
    font-family: "IBMPlex";
    src: url('../fonts/IBMPlexSansArabic-Bold.ttf') format('truetype');
    font-weight: $font--bold;
}