.signup{
    background: var(--bg-color--white);
    form{
        width: 100%;
        display: grid;
        justify-items: center;

        .StripeElement{
            width: 100%
        }
    }
    .signup__form{
        .signup__price_container{
            background: var(--bg-color--blue-alter);
            border-radius: 16px;
            justify-items: center;
            .signup__regular_price{
                font-weight: bold;
                font-size: var(--text-size--8);
                text-decoration: line-through;
            }
        }
        .signup__promo_container{
            background: var(--bg-color--second);
            border-radius: 16px;
            justify-items: center;
        }
    }
    .signup__stripe_logo{
        width: 86px;
        margin: auto;
        
    }
    
}
.singup_successfuly{
    height: 100vh;
    img{
        width: 11.8em;
    }
    .singup_successfuly__container{
        background: var(--bg-color--white);
        border-radius: 16px;
        
    }
}