.ios_payment_display {
    background-color: white;
    border-radius: 1rem;


    .img_container {
        padding: 2rem;
        border-radius: 1rem;
        background: linear-gradient(135deg, rgb(0, 180, 216) 0%, rgb(0, 119, 182) 100%);

        img {
            width: 12rem;
        }
    }
}