@import 'styles/general/variables.scss';

.side_menu {
    height: calc(100vh - 80px);
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s width;
    overflow: hidden;
    max-width: 100vw;

    &:not(.open) {
        width: 0;
    }

    &.open {
        width: 100vw;
        border-right: 2px solid var(--border-color--third-alter);
        @media screen and (min-width: #{$size--tablet}) {
            width: 25rem;
        }
    }

    .side_menu__link_container {
        margin: 1rem;

        a {
            display: flex;
            font-size: var(--text-size--4);
            color: var(--text-color--third);
            font-weight: bold;
        }
    }

}