.header {
    &.mobile_header {
        padding-top: 4rem;    
    }

    background: linear-gradient(135deg, rgb(0, 180, 216) 0%, rgb(0, 150, 200) 100%);
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 1.14em;

    .side_menu_bars {
        cursor: pointer;
        color: var(--text-color--third);
        font-weight: bold;
    }

    img {
        width: 7.26em;
    }
    .header__button{
        background: var(--bg-color--second);
        border-radius: 8px;
        .user_info__default, .header__home {
            width: 3em;
            filter: invert(100%) sepia(1%) saturate(3188%) hue-rotate(328deg) brightness(112%) contrast(100%);
        }

        .account__user_photo{
            width: 3em;
            height: 3em;
            border-radius: 8px;
            object-fit: cover;
        }
    }

    a {
        display: flex;
        font-size: var(--text-size--4);
        color: var(--text-color--third);
        font-weight: bold;
    }
}