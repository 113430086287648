.change_recovered_password{
    form{
        width: 100%;
        display: grid;
        justify-items: center
    }
    .change_recovered_password__form{
        background: var(--bg-color--white);
        border-radius: 16px;
    }
}
